<template>
  <div class="sale" style="height: 100%;">
    <van-pull-refresh
      v-model="isLoading"
      @refresh="onRefresh"
      style="height: calc(100% - 40px); overflow-y: auto;"
    >
      <van-form class="sale_form" @submit="onSubmit">
        <van-row type="flex" justify="center">
          <van-col span="22">
            <van-field
              readonly
              clickable
              name="picker"
              :value="form.game"
              label="选择游戏"
              placeholder="请选择"
              :left-icon="form.gameicon"
              @click="changeClick(3)"
            />
            <van-popup
              v-model="form.gamePicker"
              position="bottom"
              duration="0.15"
            >
              <van-picker
                show-toolbar
                value-key="channelName"
                :columns="form.games"
                @confirm="popConfirm"
                @cancel="form.gamePicker = false"
              />
            </van-popup>
          </van-col>
          <van-col span="22">
            <van-field
              readonly
              clickable
              name="picker"
              :value="form.server"
              label="选择区服"
              placeholder="请选择"
              :left-icon="form.servericon"
              @click="changeClick(1)"
            />
            <van-popup
              v-model="form.serverPicker"
              position="bottom"
              duration="0.15"
            >
              <van-picker
                show-toolbar
                value-key="server_name"
                :columns="form.servers"
                @confirm="popConfirm"
                @cancel="form.serverPicker = false"
              />
            </van-popup>
          </van-col>
          <van-col span="22">
            <van-field
              readonly
              clickable
              name="picker"
              :value="form.role"
              label="选择角色"
              placeholder="请选择"
              :left-icon="form.roleicon"
              @click="changeClick(2)"
            />
            <van-popup
              v-model="form.rolePicker"
              position="bottom"
              duration="0.15"
            >
              <van-picker
                show-toolbar
                value-key="chrname"
                :columns="form.roles"
                @confirm="popConfirm"
                @cancel="form.rolePicker = false"
              />
            </van-popup>
          </van-col>
          <van-col span="22" class="empower">
            <van-field
              name="checkbox"
              label="授权查看角色属性及携带物品"
              :left-icon="form.checkicon"
            >
              <template #input>
                <van-checkbox v-model="form.empowerCheck" shape="round"
                  >勾选授权</van-checkbox
                >
              </template>
            </van-field>
          </van-col>
          <!-- <van-col span="22" class="pricew">
                        <van-field
                            name="checkbox"
                            label="还价授权"
                            :left-icon="form.priceicon"
                        >
                            <template #input>
                                <van-checkbox v-model="form.priceCheck" shape="round">允许还价</van-checkbox>
                            </template>
                        </van-field>
                    </van-col> -->
        </van-row>
        <div class="price_w">
          <van-row type="flex" justify="center">
            <div
              v-show="form.role"
              style="display: flex;justify-content: center;flex-wrap: wrap;"
            >
              <van-col span="22">
                <van-field
                  label="验证手机号："
                  v-model="form.phone"
                  readonly
                  error-message="验证手机为账号绑定手机，无法修改"
                />
              </van-col>
              <van-col span="22" class="price_y">
                <van-field
                  v-model="form.yzm"
                  center
                  label="手机验证码："
                  oninput="value=value.replace(/[^\d]/g,'')"
                  placeholder="请输入验证码"
                >
                  <template #button>
                    <GetYzmCode :username="phone"></GetYzmCode>
                  </template>
                </van-field>
              </van-col>
            </div>
            <van-col span="22" class="price_p">
              <van-field
                center
                label=""
                v-model="form.price"
                oninput="value=value.replace(/[^\d]/g,'')"
                @keyup="getmoneyInp"
                placeholder="请输入出售总价"
              />
            </van-col>
          </van-row>
        </div>
        <van-row type="flex" justify="center" class="van_rule">
          <van-col span="22">
            <van-row>
              <van-col span="12" class="sale_money">
                <p>
                  售出预计到手：<span>¥{{ form.saleMoney }}</span>
                </p>
              </van-col>
              <van-col span="12">
                <van-field name="checkbox" label="" class="rule_w">
                  <template #input>
                    <van-checkbox v-model="form.ruleCheck" shape="round"
                      >已阅读上架规则</van-checkbox
                    >
                  </template>
                  <template #button>
                    <van-popover
                      v-model="showPopover"
                      trigger="click"
                      theme="dark"
                      placement="top-end"
                      :get-container="getContainer"
                      @open="isOpen"
                    >
                      <div class="pop_wrap" v-show="isShowOpen">
                        <p>
                          1、角色成功售出后平台将收取部分信息费。角色交易收费规则：信息费＝交易额×{{
                            sellingInformation
                          }}%，最低为{{ informationMin }}元，最高为{{
                            informationMax
                          }}元。（需卖家出）
                        </p>
                        <p>
                          2、角色申请上架后需经历审核期、公示期、出售期。 <br />
                          审核期：上架登记后需经过客服人工审核，过审后才是成功上架，进入公示期。<br />
                          公示期：公示期为{{
                            publicityPeriod
                          }}小时，期间账号只做展示，不可购买。<br />
                          出售期：出售期为{{
                            salePeriod
                          }}天，到期未出售则自动下架。
                        </p>
                      </div>
                      <template #reference>
                        <van-icon
                          name="question-o"
                          class="iconw"
                          size="1.3rem"
                        />
                      </template>
                    </van-popover>
                  </template>
                </van-field>
              </van-col>
            </van-row>
          </van-col>
          <van-col span="21" class="sumbit_btn">
            <van-button
              round
              type="info"
              size="large"
              color="linear-gradient(to right, #FF704D, #F73727)"
              native-type="submit"
              >申请上架</van-button
            >
          </van-col>
        </van-row>
      </van-form>
    </van-pull-refresh>

    <div class="pop_wrap">
      <van-popup v-model="saleShow" position="top">
        <div class="pop_w">
          <p>
            申请上架后此角色将会进入封禁状态，取消申请或下架取回后解除封禁。
          </p>
          <p class="red">( 注：交易行内物品不予展示，请自行取回至背包 )</p>
        </div>
        <div class="pop_btns">
          <van-button
            round
            type="info"
            size="small"
            color="linear-gradient(to right, #FF704D, #F73727)"
            @click="saleClick"
            >确认</van-button
          >
          <van-button
            plain
            round
            type="info"
            size="small"
            @click="saleShow = false"
            >取消</van-button
          >
        </div>
      </van-popup>
    </div>
    <van-dialog v-model="show" title="提示" theme='round-button' confirmButtonText="知道了">
      <!-- <div>
        <img style="width:3rem;padding-top: 8px;" src="@/assets/images/200069.png" />
      </div> -->
      <div style="color: #646566;padding: 6px 24px;font-size: 14px;line-height: 20px;">{{dialog_mes}}</div>
      
    </van-dialog>
  </div>
</template>
<script>
// import Top from '@/components/top'
import GetYzmCode from "@/components/getYzmCode";

export default {
  name: "Salerole",
  components: {
    // Top,
    GetYzmCode,
  },
  data() {
    return {
      dialog_mes:'',
      show:false,
      isShowOpen: "false",
      form: {
        game: "",
        channelId: "",
        games: [],
        gamePicker: false,
        gameicon: require("../../assets/images/sale_icon1.png"),
        server: "",
        server_id: "",
        servericon: require("../../assets/images/sale_icon2.png"),
        serverPicker: false,
        servers: [],
        role: "",
        seedname: "",
        roleicon: require("../../assets/images/sale_icon3.png"),
        rolePicker: false,
        roles: [],
        checkicon: require("../../assets/images/sale_icon4.png"),
        empowerCheck: false,
        priceicon: require("../../assets/images/sale_icon6.png"),
        priceCheck: false,
        phone: "",
        yzm: "",
        price: "",
        ruleCheck: false,
        saleMoney: 0,
      },
      showPopover: false,
      // phoneShow: false,
      phone: "",
      yzmCode: "",
      saleShow: false,
      sellingInformation: 0,
      informationMin: 0,
      informationMax: 0,
      publicityPeriod: 0,
      salePeriod: 0,
      isLoading: false,
    };
  },
  created() {
    if (!localStorage.getItem("token")) {
      return false;
    }
    //获取绑定的手机号
    this.phone = localStorage.getItem("username")
      ? localStorage.getItem("username")
      : "";
    this.form.phone = this.phone.substr(0, 3) + "****" + this.phone.substr(7);
    //获取渠道列表
    this.gameChannelList();
    //获取交易规则
    // this.getRule();
  },
  methods: {
    // 下拉刷新
    onRefresh() {
      //点击确认后页面重置
      Object.assign(this.$data.form, this.$options.data().form);
      if (!localStorage.getItem("token")) {
        return false;
      }
      //获取绑定的手机号
      this.phone = localStorage.getItem("username")
        ? localStorage.getItem("username")
        : "";
      this.form.phone = this.phone.substr(0, 3) + "****" + this.phone.substr(7);
      //获取渠道列表
      this.gameChannelList();
      //获取交易规则
      this.getRule();
    },

    // 验证时候登录
    isToken() {
      let token = localStorage.getItem("token");
      if (!token) {
        this.$router.push({
          name: "Login",
        });
      }
    },
    //返回一个特定的 DOM 节点，作为挂载的父节点,挂在气泡框
    getContainer() {
      return document.querySelector(".van_rule");
    },
    //提交申请
    onSubmit() {
      this.isToken();
      if (!localStorage.getItem("token")) {
        return false;
      }
      if (this.form.role) {
        if (this.form.empowerCheck) {
          if (this.form.yzm != "") {
            if (!/^[1-9][\d]*$/.test(this.form.price.trim())) {
              this.$toast({
                message: "出售价格内不能含小数点",
                duration: 1500,
              });
              return false;
            } else if (this.form.price.trim() < this.informationMin) {
              this.$toast({
                message: "价格不能低于最低出售信息费",
                duration: 1500,
              });
              return false;
            }
            if (this.form.price < this.informationMin) {
              this.$toast({
                message: "价格不能低于最低出售信息费",
                duration: 1500,
              });
              return false;
            }
            if (this.form.ruleCheck) {
              //this.saleShow = true;
              //验证验证码
              this.$api.register
                .checkCode({
                  username: this.phone,
                  code: this.form.yzm,
                })
                .then((res) => {
                  if (res.code == 200) {
                    this.saleShow = true;
                  } else {
                    this.$toast({
                      message: "验证码错误！",
                      duration: 1500,
                    });
                  }
                })
                .catch((err) => {
                  console.log(err);
                });
            } else {
              this.$toast({
                message: "请先阅读上架规则并勾选",
                duration: 1500,
              });
            }
          } else {
            this.$toast({
              message: "请填写正确的验证码",
              duration: 1500,
            });
          }
        } else {
          this.$toast({
            message: "请勾选查看授权",
            duration: 1500,
          });
        }
      } else {
        this.$toast({
          message: "请选择申请上架角色",
          duration: 1500,
        });
      }
    },
    //确认提交
    saleClick() {
      this.saleShow = false;
      this.$api.sale
        .onSale({
          channelId: this.form.channelId,
          serverId: this.form.server_id,
          seedname: this.form.seedname,
          price: this.form.price,
          code: this.form.yzm,
        })
        .then((res) => {
          if(res.code==400){
            this.$toast({
              message: res.msg,
              duration: 1500,
            });
            return false
          }else if(res.code==10000){
            this.show = true;
            this.dialog_mes = res.msg
            return false
          }
        });
      this.$toast({
        message: "申请已提交",
        duration: 1500,
      });
      //点击确认后页面重置
      Object.assign(this.$data.form, this.$options.data.call(this).form);
      //获取绑定的手机号
      this.phone = localStorage.getItem("username")
        ? localStorage.getItem("username")
        : "";
      this.form.phone = this.phone.substr(0, 3) + "****" + this.phone.substr(7);
      //获取渠道列表
      this.gameChannelList();
    },
    //获取验证码
    // getCode(val) {
    //     if (val) {
    //         this.yzmCode = val;
    //     }else{
    //         this.yzmCode = '';
    //     }
    // },
    //游戏渠道列表
    gameChannelList() {
      let data = {
        onsale_type: 1,
      };
      this.$api.helper
        .getChannel(data)
        .then((res) => {
          this.isLoading = false;
          if (res.code == 200 && res.data.length > 0) {

            if (sessionStorage.getItem("type")==='24') {
              let arr_item = []
              res.data.forEach((item,index)=>{
                if(item.channelId==24){
                  // console.log(res.data[index])
                  // res.data = []
                  // res.data.push(res.data[index])
                  arr_item.push(res.data[index])
                }
              })
              res.data = arr_item
              // console.log(res.data)
            }else{
              res.data.forEach((item,index)=>{
                if(item.channelId==24){
                  res.data.splice(index,1)
                }
              })
            }
            
            this.form.games = res.data;
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    //根据渠道获取区服列表
    serverList() {
      this.$api.helper
        .getServerList({
          channel_id: this.form.channelId,
        })
        .then((res) => {
          if (res.code == 200) {
            for (var key in res.data) {
              this.form.servers.push({
                server_name: res.data[key].server_name,
                server_id: res.data[key].server_id,
              });
            }
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    //根据区服获取角色列表
    roleList() {
      this.$api.helper
        .getPlayerList({
          channel_id: this.form.channelId,
          server_id: this.form.server_id,
          type: 1,
        })
        .then((res) => {
          if (res.code == 200 && res.data.length > 0) {
            this.form.roles = res.data;
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    //选择框确认
    popConfirm(val) {
      if (this.form.gamePicker) {
        if (this.form.games.length == 0) {
          this.form.gamePicker = false;
          return false;
        }
        //游戏切换
        this.form.game = val.channelName;
        this.form.channelId = val.channelId;
        this.form.gamePicker = false;
        this.form.server = "";
        this.form.role = "";
        this.form.servers = [];
        this.form.roles = [];
        this.serverList();
        //获取channelId对应规则
        this.getRule();
      } else if (this.form.serverPicker) {
        if (this.form.servers.length == 0) {
          this.form.serverPicker = false;
          return false;
        }
        //区服切换
        this.form.server = val.server_name;
        this.form.server_id = val.server_id;
        this.form.serverPicker = false;
        this.form.role = "";
        this.form.roles = [];
        // this.phoneShow = false;
        this.roleList();
      } else {
        if (this.form.roles.length == 0) {
          this.form.rolePicker = false;
          return false;
        }
        this.form.role = val.chrname;
        this.form.seedname = val.seedname;
        this.form.rolePicker = false;
        // this.phoneShow = true;
      }
    },
    //获取交易规则信息
    getRule() {
      this.$api.sale
        .getRule({ channel_id: this.form.channelId })
        .then((res) => {
          this.isLoading = false;
          if (res.code == 200) {
            this.sellingInformation = res.data.sellingInformation;
            this.informationMin = res.data.informationMin;
            this.informationMax = res.data.informationMax;
            this.publicityPeriod = res.data.publicityPeriod;
            this.salePeriod = res.data.salePeriod;
          }
        });
    },
    //选择框选择顺序提示
    changeClick(key) {
      this.isToken();
      if (!localStorage.getItem("token")) {
        return false;
      }
      if (key == 1) {
        if (this.form.game) {
          this.form.serverPicker = true;
        } else {
          this.$toast({
            message: "请先选择项目，再选区服",
            duration: 1500,
          });
        }
      } else if (key == 2) {
        if (this.form.server) {
          this.form.rolePicker = true;
        } else {
          this.$toast({
            message: "请先选择区服，再选角色",
            duration: 1500,
          });
        }
      } else if (key == 3) {
        this.form.gamePicker = true;
      }
      // if(!localStorage.getItem('token')){
      //     Notify.clear;
      // }
    },
    //获取售出价格
    getmoneyInp() {
      //console.log(this.form.price);
      let infofee = 0,
        truefee = 0;
      infofee = this.form.price * this.sellingInformation * 0.01;
      if (infofee < this.informationMin) {
        truefee = this.informationMin;
      } else if (infofee > this.informationMax) {
        truefee = this.informationMax;
      } else {
        truefee = infofee;
      }
      this.form.saleMoney =
        this.form.price - truefee < 0 ? 0 : this.form.price - truefee;
    },
    //设置选择游戏后才能点开交易规则
    isOpen() {
      if (this.form.game == "") {
        this.isShowOpen = false;
        this.$toast({
          message: "请先选择游戏",
          duration: 1500,
        });
      } else {
        this.isShowOpen = true;
      }
    },
  },
};
</script>
<style>
.sale_form {
  padding: 4% 0;
}
.sale_form .van-col {
  margin-bottom: 3%;
}
.van_rule .van-col{
  margin-bottom: 1px !important;
}
.sale_form .van-cell {
  background-color: #efefef;
  border-radius: 3px;
  padding: 10px 2% 10px 15px;
}
.sale_form .van-field__label {
  color: #313131;
}
.sale_form .empower .van-field__label {
  width: 13em;
}
.sale_form .pricew .van-field__label {
  width: 13em;
}
.sale_form .pricew .van-field__label span {
  position: relative;
}
.sale_form .pricew .van-field__label span::after {
  content: "(可不选)";
  position: absolute;
  top: -4px;
  right: -53px;
  color: #b1b1b1;
}
.price_w {
  border-top: 1px solid #bbb;
  border-bottom: 1px solid #bbb;
}
.price_w .van-col {
  margin-bottom: 0;
}
.price_w .van-cell {
  background-color: #fff;
  padding: 10px 0;
}
.price_w .yzmcode_w button {
  top: 0;
}
.price_y input {
  background-color: #efefef;
  border-radius: 3px;
  width: 60%;
  padding: 2%;
}
.price_p input {
  background-color: #efefef;
  padding: 3% 2%;
  border-radius: 3px;
  font-size: 1rem;
  text-align: center;
}
.price_p .van-field__body {
  position: relative;
}
.price_p .van-field__body::after {
  content: "元";
  position: absolute;
  top: 50%;
  right: 2%;
  transform: translate(0, -50%);
  font-size: 1rem;
}
.sale_form .sumbit_btn button {
  font-size: 1.2rem;
}
.sale_form .rule_w {
  background-color: #fff;
  padding: 10px 0;
}
.rule_w .van-field__body {
  justify-content: flex-end;
}
.rule_w .van-field__control {
  width: 100%;
}
.rule_w .van-field__button {
  display: flex;
  padding-left: 0;
}
.rule_w .van-popover__wrapper {
  display: flex;
}
.rule_w .van-checkbox__label {
  font-size: 13px;
}
/* 气泡框样式 */
.van_rule .van-popup {
  width: 20rem;
}
.van_rule .pop_wrap {
  /* background-color: #efefef; */
  text-align: left;
  padding: 3%;
  line-height: 1.7em;
}
/* .van_rule .pop_wrap p:first-child{
    color: #f00;
} */
.van_rule .van-popup .van-popover__arrow {
  display: none;
}
.van_rule .sale_money {
  text-align: left;
  padding: 10px 0;
  font-size: 13px;
  line-height: 24px;
}
.van_rule .sale_money span {
  color: #1ac917;
}
</style>
